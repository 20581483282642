import './App.css';
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Build/WebGLBuilds.loader.js",
    dataUrl: "Build/WebGLBuilds.data",
    frameworkUrl: "Build/WebGLBuilds.framework.js",
    codeUrl: "Build/WebGLBuilds.wasm",
  });

  return <Unity unityProvider={unityProvider} />;
}

export default App;
